// scss/styles.scss
@import url("https://use.typekit.net/mpj1rai.css");
@font-face {
    font-family: 'founders_grotesklight';
    src: url('/hapai-public/fonts/foundersgrotesk-light-webfont.woff2') format('woff2'),
         url('/hapai-public/fonts/foundersgrotesk-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;

}
@font-face {
    font-family: 'tobiaslight';
    src: url('/hapai-public/fonts/tobias-light-webfont.woff2') format('woff2'),
         url('/hapai-public/fonts/tobias-light-webfont.woff') format('woff'),
         url('/hapai-public/fonts/tobias-light.oft') format('oft');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'tobiasmedium';
    src: url('/hapai-public/fonts/tobias-medium-webfont.woff2') format('woff2'),
         url('/hapai-public/fonts/tobias-medium-webfont.woff') format('woff'),
         url('/hapai-public/fonts/tobias-medium.oft') format('oft');
    font-weight:500;
    font-style: normal;
    font-display: auto;
}


$body-font:'founders_grotesklight',  sans-serif;
$heading-font:'tobiaslight',  sans-serif;
$heading-font-bold:'tobiasmedium',  sans-serif;
$button-font: "roc-grotesk-wide", sans-serif;


$headings-font-family:$heading-font;
$headings-font-weight:300;
$headings-line-height:0.91;


$font-family-base:$body-font;
$font-weight-base:300;
$line-height-base:1.25;

// Toggle global options
$enable-gradients: false;
$enable-shadows: false;
$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 1.25,
  2: $spacer * 1.875,
  3: $spacer * 2.5,
  4: $spacer * 3.75,
  5: $spacer * 5,
);

$new-spacer:(
    6: $spacer * 7.5,
    7: $spacer * 10,
    6vw:6vw,
    7vw:7vw,
    10px:10px,
    50px:50px,
    55px:55px,
    70px:70px,
);

$spacers:map-merge($spacers, $new-spacer);


$new-widths:(
    1vw:1vw,
    2vw:2vw,
    2p5vw:2.5vw,
    3vw:3vw,
    3p5vw:3.5vw,
    4vw:4vw,
    4p5vw:4.5vw,
    5vw:5vw,
    6vw:6vw,
    7p5vw:7.5vw,
    10vw:10vw,
    11vw:11vw,
);

$spacers:map-merge($spacers, $new-widths);

// Define your custom colors
$custom-dark: #122E2A;
$custom-primary: #F29054;
$custom-accent-green:#B1CB8F;
$custom-accent-blue:#77A5BE;
$custom-accent-yellow:#F0E387;
$custom-neutral:#F9F6EE;
$custom-white:#fff;
$custom-dark-grey:#989A9A;
$custom-light-grey:#CED7D7;



// Override Bootstrap variables
$primary: $custom-dark;
$secondary:$custom-accent-green;
$success:$custom-primary;
$tertiary:$custom-accent-blue;
$dark:$custom-dark;
$light:$custom-neutral;
$body-bg: $custom-neutral;
$body-color: $custom-dark; 

$form-check-input-border:0;
$form-check-input-border-radius: 0;
$form-check-input-bg:$custom-light-grey;
$form-floating-height: 3em;
$form-floating-input-padding-t:2em;
$form-floating-input-padding-b:1.5em;

// Include functions first

@import "bootstrap/scss/functions";



$grid-breakpoints: (
  xs: 0,
  btnsBreak:600px,
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  xl: 1600px,
  xxl: 1920px
);



$cust-grid-breakpoints:(
    highRes:3480px,
    navBreak:10000px,

);

$grid-breakpoints:map-merge($grid-breakpoints, $cust-grid-breakpoints);

$container-max-widths: (
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  xl: 1600px,
  xxl: 1920px
);

$display-font-family:$heading-font;
$display-line-height:1.15em;

$accordion-border-radius: 0;
$accordion-icon-color: $success;
$accordion-icon-active-color:$success;
$accordion-button-active-bg: $light;
$accordion-border-color:$custom-dark-grey;
$accordion-border-width:0;
$accordion-padding-x:0;
$accordion-padding-y:0;
// $accordion-button-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' stroke='#{$accordion-icon-color}' fill='none'><path d='M-0.00390625 10H10.4989V20.5028'/><path d='M21 10L10.4972 10L10.4972 -0.502773'/></svg>");
// $accordion-button-active-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' stroke='#{$accordion-icon-color}' fill='none'><path d='M3.07422 2.5752L10.5008 10.0018L3.07422 17.4284'/><path d='M17.9258 17.4268L10.4992 10.0002L17.9258 2.5736'/></svg>");

$accordion-button-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' stroke='#122E2A' fill='none'><path d='M-0.00390625 10H10.4989V20.5028'/><path d='M21 10L10.4972 10L10.4972 -0.502773'/></svg>");
$accordion-button-active-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' stroke='#122E2A' fill='none'><path d='M3.07422 2.5752L10.5008 10.0018L3.07422 17.4284'/><path d='M17.9258 17.4268L10.4992 10.0002L17.9258 2.5736'/></svg>");



// Import Bootstrap variables

@import "bootstrap/scss/variables";

$new-colors:(
    "accent-blue":$custom-accent-blue,
    "accent-yellow":$custom-accent-yellow,
    "custom-grey":$custom-light-grey,
    "custom-dark-grey":$custom-dark-grey,
    "cust-neutral":$custom-neutral
);

$theme-colors:map-merge($theme-colors, $new-colors);

////Buttons
$btn-font-family: $button-font;
$btn-line-height:$font-size-base;
$btn-font-weight:500;





// Import the rest of Bootstrap
@import "bootstrap/scss/variables-dark";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";


@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";




@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

@import "bootstrap/scss/forms";

@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
$dropdown-link-hover-bg:$primary;
@import "bootstrap/scss/dropdown";

$navbar-light-active-color:$secondary;
$navbar-light-toggler-border-color:$light;
$navbar-toggler-border-radius:0;
$navbar-toggler-focus-width: 0;
$navbar-toggler-padding-y:0;
$navbar-toggler-padding-x:0;

@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";

@import "bootstrap/scss/accordion";


// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
$modal-content-border-radius:0;
$modal-backdrop-bg:$primary;
$modal-backdrop-opacity:.4;
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";
// Helpers
// @import "bootstrap/scss/helpers";
// Utilities

@import "bootstrap/scss/utilities/api";
// Custom styles
// @import "icon-list";






///general custom


html{
    scroll-behavior: unset !important;
}
body{
    position: relative;
    // font-size:1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    font-smooth: never;
}
.row{
    margin:0;
}

.relative{
    position: relative;
}


a{
    text-decoration: none;  
}

div p:last-of-type{
    margin-bottom:0;
}

p {
    white-space: pretty; // Use correct CSS property for pretty wrapping
  
    &.--btn-font {
      font-family: $button-font;
    }
  
    a {
        white-space: nowrap;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }

li:not(.nav-item, .dropdown-menu li){
    a:not(.btn-linkedin, .btn-share){
        white-space: nowrap;
      &:hover {
        border-bottom: 1px solid;
      }
    }
}

  


//list styles
.list-unstyled.border-style{
    li{
        padding: 1em 0;
        margin-bottom: 1em;
        border-bottom: 1px solid $primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p{
            margin-right:20px;
        }
        &:last-of-type{
            border-bottom-width: 0;
        }
        div{
            p{
                text-align: right;
                margin: 0;
            }
        }
    }
    &.other{
        li{
            margin:0;
            @include media-breakpoint-up(md){
                &:last-of-type{
                    padding-bottom: 0;
                }
            }
        }
    }
}

/// margins for flex col groups 
@include media-breakpoint-up(md){
    .left-m{

        left:20px
    }
    .right-m{
        right:20px
    }
}


// forms
.style-1{
    input, textarea, select{
        border-width: 0;
        border-bottom-width: 1px;
        border-radius:0;
        &:focus{
            box-shadow: none;
        }
        &:focus-visible{
            outline-color:$secondary
        }

    }

    input[type="checkbox"]{
        border-width:1px;
    }
    .form-control{
        // height:3em !important;
        margin-top: 1em;
        padding-left: 0;
    }

    select,
    .form-floating label{
        
            padding-left: 0;
        
    }

    .form-floating>.form-control:focus, 
    .form-floating>.form-control:not(:placeholder-shown), 
    .form-floating>.form-control-plaintext:focus, 
    .form-floating>.form-control-plaintext:not(:placeholder-shown) {
        padding-top:1em;
        padding-bottom: .5em;
    }

    label span{
        font-weight:bold
    }
}

.form-floating>.form-control:focus~label::after{
    background-color: transparent;
}

.bg-white .style-1{
        input:not(input[type=checkbox], input[type=radio]), textarea, select{
            background-color: $custom-white;
        }
}


.form-check-input[type=radio]{
    outline:2px solid $primary;
    border: 2px solid $light;
    margin-top: 4px;
}

.form-check-input[type=radio]:not(.form-check-input:checked){
    background-color: transparent;

}
.form-check-input:checked[type=radio]{
    outline:2px solid $primary;
    border: 2px solid $light;
    background-image:none ;
}




textarea#customer-message{
    height:100px
}


///Images
.img-fluid{
    width: 100%;
}



////Headings/Typography
$ch1:5.625rem;
$ch2:4.063rem;
$ch3:2.188rem;
$ch4:2rem;
$ch5:1.625rem;
$ch6:1.25rem;
$ch7: 1rem;

[class*="cust-font"]{
    font-family: $heading-font;
    line-height:1.15em;
    margin-bottom: 0;
    font-weight: 300;
    text-wrap: balance;
    &.--body-font{
        font-family: $body-font; 
    }
    &.--heading-font{
        font-family: $heading-font; 
    }
    &.--balance{
        text-wrap: balance;
    }
    &.--pretty{
        text-wrap: pretty;
    }
    &.--light-text{
        font-weight: 300;
    }
    p, a{
        font-size:inherit
    }
}

.cust-font-1{
    font-size:calc($ch1*.59);
    line-height:1em;
}
.cust-font-2{
    font-size:calc($ch2*.6);
    line-height:1em;
}   
.cust-font-3{
    font-size:calc($ch3*.9);
}
.cust-font-4{
    font-size:calc($ch4*.9);
}
.cust-font-5{
    font-size:calc($ch5*.9);
}
.cust-font-6{
    font-size:calc($ch6*.9);
}

.cust-font-7{
    font-size:calc($ch7*.8);
    text-transform: uppercase;
    font-family: $button-font;
    font-weight: 500;
    &.--light-weight{
        font-weight:300;
    }

}

@include media-breakpoint-up(sm){
    .cust-font-1{
        font-size:$ch1;
    }
    .cust-font-2{
        font-size:$ch2;
    }
    .cust-font-3{
        font-size:$ch3;
    }
    .cust-font-4{
        font-size:$ch4;
        &.--sm{
            font-size:$ch5; 
        }
    }
    .cust-font-5{
        font-size:$ch5;
    }
    .cust-font-6{
        font-size:$ch6;
    }


}


@include media-breakpoint-up(md){
    .cust-font-1{
        font-size:calc($ch1*.65);
    }
    .cust-font-2{
        &.hero-font{
            font-size:calc($ch2*.8);
        }
    }
}

@include media-breakpoint-up(lg){
    .cust-font-1{
        font-size:6.5vw;
    }
    .cust-font-2{
        &.hero-font{
            font-size:$ch2;
        }
    }
    .cust-font-4{
        &.--sm{
            font-size:$ch4;
        }
    }

    p, a:not(.btn, .nav-link), li, input, label, .form-control, button .btn{
        font-size:calc($font-size-base*1.2);
    }
    [class*="cust-font"] a{
        font-size:inherit
    }
}
@include media-breakpoint-up(xl){
    .cust-font-2{
        font-size:calc($ch2*1.2);
        &.hero-font{
            font-size:calc($ch2*1.2);
        }
    }
    .cust-font-3{
        font-size:calc($ch3*1.3);
    }
    .cust-font-4{
        font-size:calc($ch4*1.3);
        &.--sm{
            font-size:$ch4*2;
        }
    }
    .cust-font-5{
        font-size:calc($ch5*1.3);
    }
    .cust-font-6{
        font-size:calc($ch6*1.3);
    }
    .cust-font-7{
        font-size:calc($ch7*1);
    }

    
}


@include media-breakpoint-up(highRes){
    .cust-font-2{
        font-size:calc($ch2*2.3);
        &.hero-font{
            font-size:calc($ch2*2.3);
        }
    }
    .cust-font-3{
        font-size:calc($ch3*2.3);
    }
    .cust-font-4{
        font-size:calc($ch4*2.3);
        &.--sm{
            font-size:$ch4*2;
        }
    }
    .cust-font-5{
        font-size:calc($ch5*2.3);
    }
    .cust-font-6{
        font-size:calc($ch6*2.3);
    }
    .cust-font-7{
        font-size:calc($ch7*2);
    }
    p, a:not(.btn, .nav-link), li, input, label, .form-control{
        font-size:calc($font-size-base*2.8);
    }

}




////BUTTONS///////
 @mixin responsive-btn($size) {
    padding:calc($size*1.2) calc($size*1.07);
    padding-bottom:$size;
    margin-top:calc($size*2.2);
    font-size:calc($size*0.85);
    border-radius:calc($size*1.25);
}




@mixin thin-btn($size) {
    padding:calc($size*.6) calc($size*1.07);
    padding-bottom:calc($size*.4);
}


.btn{
    text-transform: uppercase;
    border-width:0;
    transition: all .2s ease-in-out;
    @include responsive-btn(1rem);

    &[class*="btn-outline"] {
        border-width: 1px;
    }
    &.btn-light{
        --bs-btn-hover-bg:#B1CB8F; 
    }

    &.thin-pad{
        @include thin-btn(1rem); 
    }

    @include media-breakpoint-up(highRes){
        @include responsive-btn(2rem);
        &.thin-pad{
            @include thin-btn(2rem); 
        }
    }

    &:hover{
        border-top-left-radius: 0;
        transition: all .2s ease-in-out;
    }

    @include media-breakpoint-down(btnsBreak){
        width: 100%;
    }
}





.button-group{
    z-index: 1;
    position: relative;
    
}
button{
    background-color: transparent;
    border-width: 0;
}


///Button icon - for color groups see .primary-custom groups
.btn-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    p{
        margin-top:5px;
        margin-right: 15px;
    }
    svg{
        transition: all .2s ease-in-out;
        width:20px;
        height:20px;
        stroke:$primary
    }
    &:hover{
        transition: all .2s ease-in-out;
        svg{
            transition: all .2s ease-in-out;
            margin-left:.5rem;
        }
    }

    @include media-breakpoint-up(xxl){
        svg{
            width:30px;
            height:30px;
        }
    }
    @include media-breakpoint-up(highRes){
        svg{
            width:40px;
            height:40px;
        }
    }
}

.btn-share,
.btn-linkedin{
    svg{
        width: 40px;
        height: 40px;
    }
    &:hover{
        svg{
            transition: all .2s ease-in-out;
            margin-left: 0;
        }
    }


    @include media-breakpoint-up(highRes){
        svg{
            width:80px;
            height:80px;
        }
    }
}

.btn-linkedin{
    svg{
        fill: $primary;
        stroke:none;
        circle{
            fill:$light;
        }
    }

    &:hover{
        svg{
            fill: $success;
        }
    }


    &.--yellow{
        svg{
            circle{
                fill: $custom-accent-yellow;
            }
        }

        &:hover{
            circle{
                fill: $secondary;
            }
            svg{
                fill: $dark;
            }
        }
    }

}

.btn-share{
    svg{
        stroke: $primary;
        stroke-width: 1.5px;
        fill:none;
        circle{
            fill:$light;
            stroke:none;
        }
    }

    &:hover{
        svg{
            stroke: $success;
        }
    }


    &.--yellow{
        svg{
            circle{
                fill: $custom-accent-yellow;
            }
        }

        &:hover{
            circle{
                fill: $secondary;
            }
            svg{
                stroke: $dark;
            }
        }
    }

}




//hover effect on images and divs
.image-cnr{
    position: relative;
    overflow: hidden;
    svg.cnr-animation{
        fill:$custom-neutral;
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 20%;
       
        transform-origin: bottom right;
        transform: scale(1);
        transition: transform .2s ease-in-out;
        z-index: 5;

        @include media-breakpoint-up(md){
            &.right-m{
                right: 19px;
            }
        }
        @include media-breakpoint-up(lg){
            max-width: 9vw;
        }


    }
    &.cnr-constant{
        svg.cnr-animation{
            transform: scale(1);
        } 

        &.top-left{
            svg.cnr-animation{
                bottom: unset;
                right:unset;
                left: -1px;
                top:-1px;
                transform: rotate(180deg);
                transform-origin: unset;
            }
        } 

    }


    &.cnr-hover-add{
       svg.cnr-animation{
        transform: scale(0);
       }

        &:hover{
            svg.cnr-animation{
                transform: scale(1);
            }
        }
    }

    &.cnr-hover-remove{
        &:hover{
            svg.cnr-animation{
                transform: scale(0);
            }
        }
    }

    .img-wrap{
        position: relative;
        width: calc(100% - 20px);
        height: 100%;
        overflow: hidden;
    }
}

a svg,
button svg{
    transition: margin .2s ease-in-out;
}

a:hover{
    .btn-icon,
    button{
        svg{
            transition: margin .2s ease-in-out;
            margin-left: .5rem;
        }
    }
    
}



.img-fit{
    width: 100%;
    height:100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    z-index: 0;
    &.index-negative{
        z-index: -1; 
    }
    &.img-top{
        object-position: 50% 0%; 
    }
    &.img-right{
        object-position: 100% 50%; 
    }
    @include media-breakpoint-up(sm){
        height:100%;
    }
}

.gallery-wrapper{
    .gallery-image{
        position: relative;
        overflow: hidden;
        min-height:50vw;
        padding: 10px;
        .img-fit{
            width: calc(100% - 20px);
            height: calc(100% - 20px);
        }
        @include media-breakpoint-up(sm){
            min-height:60vw;
        }
        @include media-breakpoint-up(md){
            min-height:calc(25vw - 20px)
        }
        @include media-breakpoint-up(highRes){
            padding: 20px;
            .img-fit{
                width: calc(100% - 40px);
                height: calc(100% - 40px);
            }
        }
    }
}



.home-hero{
    min-height: 100vh;
    @include media-breakpoint-up(sm){
        min-height:80vw;

    }
    @include media-breakpoint-up(md){
        min-height:60vw;
    }
    @include media-breakpoint-up(lg){
        min-height:unset;
    }
}

.training-slider-img{
    min-height: 50vh;
   

    @include media-breakpoint-up(sm){
        min-height:auto;
    }

}

.who-slider-img{
    min-height:70vw;
    width: 70%;

    &.image-cnr img{
        border-right: 1px solid $custom-accent-yellow;
    }
    @include media-breakpoint-up(sm){
        min-height:calc(90vw / 2);
        width: 50%;
    }

    @include media-breakpoint-up(md){
        min-height:calc(33.33vw / 1.5);
        width: 50%;
    }
}


.img-3-col-style,
.responsive-hero,
.responsive-img-height,
.min-img-height-g4,
.min-img-height-g3,
.min-img-height-g2,
.min-img-height-g1{
    svg.cnr-animation{
        bottom: -2px;
    }
    min-height:120vw;
    @include media-breakpoint-up(sm){
        min-height:60vw;
    }
}



.responsive-hero{
    @include media-breakpoint-up(md){
        min-height:50vw;
    }
    @include media-breakpoint-up(lg){
        min-height:44vw;
    }
    @include media-breakpoint-up(xl){
        min-height:32vw;
    }
}

.responsive-img-height{
    @include media-breakpoint-up(md){
        min-height:100%;
    }
}

.min-img-height-g3{
    @include media-breakpoint-up(md){
        min-height:45vw;
    }
}

.min-img-height-g4{
    @include media-breakpoint-up(md){
        min-height:50vw;
    }
    @include media-breakpoint-up(xxl){
        min-height:40vw;
    }
}

.min-img-height-g1{
    @include media-breakpoint-up(md){
        min-height:40vw;
    }
    @include media-breakpoint-up(xl){
        min-height:38vw;
    }
}


///set of three images, slightly taller than width
.min-img-height-g2{
    @include media-breakpoint-up(md){
        min-height:33.5vw;
        &.sm-side{
            .img-fit{
                height: 65%;
            }
        }
    }
}


///set of three images, slightly taller than width
.img-3-col-style{
    @include media-breakpoint-up(md){
        min-height:33.5vw;
    }
}

.filter-wrapper .img-3-col-style{
    @include media-breakpoint-up(md){
        min-height:30vw;
    }
}

.diff-heights{
    @include media-breakpoint-up(md){
        .col:first-of-type,
        .col:nth-of-type(2n + 1){
            .img-fit{
                height: 65%;
            }
        }
    }
}




//sections that act as buttons/links to other pages
.pod{
    position: relative;
    .button-group{
        z-index: 3;
    }

    //for non image background pods
    &.pod-flex{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &.pod-full{
        display: block;
        width: 100%;
        
        &:after{
            content: '';
            width: 100%;
            height:100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            background: rgb(0,0,0);
            background: linear-gradient(201deg, rgba(0,0,0,0) 0%, rgba(18,46,42,1) 80%);
        }
    }
    .btn-icon svg{
        stroke:$light;
    }
    &:hover{
        .btn,
        button{
            transition: all .2s ease-in-out;
            border-top-left-radius: 0;       
        }
    }
}



////background and text combinations
.custom-primary{
    position: relative;
    &.--light-green{
        background-color:$custom-accent-green; 
        h1, h2, h3, h4, p, a:not(.btn), li{
            color:$custom-dark;
        }
        .image-cnr svg.cnr-animation{
            fill:$custom-accent-green;
        }
        .btn-icon{
            transition: all .2s ease-in-out;
            svg{
                stroke:$custom-dark;
            }
            &:hover{
                p{color:$custom-neutral}
                svg{
                    stroke:$custom-neutral;
                }
            }
        }
        .side-buttons div{
            border-color: $primary;
        }
    }
    &.--dark-green{
        background-color: $custom-dark;
        color:$custom-accent-green;
        h1, h2, h3, h4, p, a:not(.btn), li{
            color:$custom-accent-green;
        }
        .svg-ribbon{
            fill:$custom-accent-green;
        }
        .image-cnr{
            svg.cnr-animation{
                fill:$primary
            }
        }

        .list-unstyled.border-style{
            li{
                border-bottom:1px solid $custom-accent-green;
                p:not(.cust-font-7){
                    color: $light;
                }
            }
        }
    }

    &.--yellow{
        background-color: $custom-accent-yellow;
        .image-cnr{
            svg.cnr-animation{
                fill:$custom-accent-yellow;
            }
        }
        .btn-icon{

            svg{
                stroke:$primary
            }
        }
        .events-style  a:hover{
            border: 1px solid $custom-accent-yellow;
        }

        .side-buttons div{
            border-color: $primary;
        }
    }

    &.--dark-green-neutral{
        background-color: $custom-dark;
        color:$custom-neutral;
        h1, h2, h3, h4, p, a:not(.btn), li{
            color:$custom-neutral;
        }

        .image-cnr svg.cnr-animation{
            fill:$custom-dark;
        }

        .swiper-controls-wrapper{
            .swiper-button-prev,
            .swiper-button-next{
                    svg {
                        path{
                            stroke:$light
                        }
            
                    }
                }
            }

        .btn-icon{
            transition: all .2s ease-in-out;
            svg{
                stroke:$custom-neutral;
            }
            &:hover{
                p{color:$custom-accent-green}
                svg{
                    stroke:$custom-accent-green;
                }
            }
            &.--orange{
                &:hover{
                    p{
                        color: $success;
                    }
                    svg{
                        stroke: $success;
                    }
                }
            }
        }
    }



    &.--orange{
        background-color:$custom-primary;
        .image-cnr{
            svg.cnr-animation{
                fill:$custom-primary
            }
        }
        .side-buttons div{
            border-color: $light;
        }
    }

    &.--blue{
        background-color:$custom-accent-blue;
        .image-cnr{
            svg.cnr-animation{
                fill:$custom-accent-blue;
            }
        }
        .events-style a:hover{
            border: 1px solid $custom-accent-blue;
        }
        
    }
}


//padding options
.alt-pad{
    @include media-breakpoint-up(md){
        .col:nth-of-type(2n){
            margin-left:16.66666667% ;
        }
    }
}
.middle-space{
    @include media-breakpoint-up(md){
        .col:first-of-type{
            .ex-pad{
                padding-right: 30px;
            }
        }
        .col:last-of-type{
            .ex-pad{padding-left: 30px;}
        }
    }
    @include media-breakpoint-up(highRes){
        .col:first-of-type{
            .ex-pad{
                padding-right: 60px;
            }
        }
        .col:last-of-type{
            .ex-pad{padding-left: 60px;}
        }
    }
}



// filter for different sections
// faq page and all filtered items
.filter-wrapper{
    .filter-item{
        display: flex;
        flex-direction: column;
        a{
            width:100%;
        }
    }
    .show {
        display: flex;
        flex-direction: column;
        a{
            width:100%;
        }
    }
}

.filter-wrapper-other{
        .filter-item{
            display: none;
            &.show {
                display: flex;
                flex-direction: column;
                a{
                    width:100%;
                }
            }
        }
}




.masking-trigger{
    overflow:hidden;

    .mask-img.one{
        z-index: 3;
    }
    .mask-img.two{
        z-index: 2;
    }

    .mask-img.three{
        z-index: 1;
    }

    .mask-item{
        overflow: hidden;
        position: relative;
    }

    .filter-hero{
        overflow:hidden;
        min-height: 60vw;
        @include media-breakpoint-up(md){
            min-height: calc(90vh - 6vw);
            max-height: calc(90vh - 6vw);
        }
    }
}

.what-we-do-page,
.home-page{
    .filter-hero{
        overflow:hidden;
        min-height: 60vw;
        @include media-breakpoint-up(md){
            min-height: calc(90vh - 80px - 6vw);
            max-height: calc(90vh - 80px - 6vw);
        }
    } 
}






.same-height-wrapper-filter .filter-wrapper{
    .filter-item{
        justify-content: space-between;

    }
    .show {
        justify-content: space-between;
        
    }
}



.same-height-wrapper-two-col,
.same-height-wrapper{
    .same-height{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .padlock{
            width: 1.5em;
            height:1.5em;
            margin-right: 0;
        }
    }
    &.other{
        .same-height{
            justify-content: flex-start;
        }
    }
}












// Partners logos
.logo-wrapper{
    img{
        width: 85%;
        max-width: 200px;
    }
    @include media-breakpoint-up(sm){
        width: 100%;
    }
    @include media-breakpoint-up(highRes){
        img{
            max-width: 400px;
        }
    }
}



// icons
.padlock{
    width: 15px;
    height:19px;
    margin-right:20px;
    @include media-breakpoint-up(highRes){
        width: 30px;
        height:38px;
        margin-right:40px;
    }
}

.quote{
    width: 50px;
    height: 50px;
    @include media-breakpoint-up(highRes){
        width: 100px;
        height: 100px;
    }
}
.quote rect{
    width: 50px;
    height: 50px;
}

.faq-cross{
    fill: none;
    stroke-width: 1.5;
}





.content-filter{
    .side-buttons{
        div{
            width: 100%;
            border-bottom: 1px solid $success;
            &:last-of-type{
                border-bottom-width: 0;
            }
        }
        .btn,
        .btn.active{
            transition: all .2s ease-in-out;
          }
   }

   &.top-buttons{
        div{
            padding-right: 10px;
            margin-bottom: 10px;
            @include media-breakpoint-up(md){
                padding-right: 1vw;
            }
        }
        .btn-light{
            border: 1px solid $light;
            &:hover,
            &.active{
                background-color: transparent;
                border: 1px solid $primary
            }
        }

   }

   &.lg-btns{
    div{
        width: 100%;
        border-bottom: 1px solid $custom-light-grey;
        &:last-of-type{
            border-bottom-width: 0;
        }
        .btn{
            padding:1.5rem 10px 2rem;
            @include media-breakpoint-up(highRes){
                padding:3rem 20px 4rem;
            }
        }
    }
   }

   .side-buttons,
   &.lg-btns{
    .btn{
        font-size: inherit;
        font-family: inherit;
        text-transform: unset;
        text-align: left;
        line-height: inherit;
        transition: font-weight .3s ease-in-out;
        &.active{
            font-weight: bold;
        }
        &:hover{
            transition: font-weight .3s ease-in-out;
            font-weight: bold;
        }
    }
   }

   

}

.accordion{
    .accordion-item{
        .accordion-header{
            width: 100%;
        }
        border-bottom-width:1px;

        .accordion-button{
            text-wrap: balance;
                align-items: flex-start;
                &:after{
                    margin-top: 5px;
                    background-size: 20px;
                    width: 20px;
                    height: 20px;
                    @include media-breakpoint-up(highRes){
                        width: 40px;
                        height: 40px;
                        margin-top: 10px;
                        background-size: 40px;
                    }
                }
            &:focus{
                box-shadow: none;
            }
        }
    }
    
    &.full-accordion{
        .accordion-item{
            border-top-width: 0;
            
        }
        .list-unstyled.border-style li{
            border-color: $custom-dark-grey
        }
    }
    &#other{
        .accordion-item{
            background-color:transparent;
            border-bottom-color: $primary;
            &:last-of-type{
                border-bottom-width:0;
            }
            button{
                background-color:transparent; 
            }
        }
    }
}



.events-style{
    .large-cust-font{
        font-size: 75px;
        line-height: 1em;
        transition: all .2s ease-in-out;
        // border-bottom:1px solid $light;
        @include media-breakpoint-up(lg){
            font-size: 150px;
        }
        @include media-breakpoint-up(highRes){
            font-size: 300px;
        }
    }
    .add-border{
        transition: all .2s ease-in-out;
        border-bottom: 1px solid $light;
    }

    a{
        position: relative;
        overflow: hidden;
        transition: all .2s ease-in-out;
        border: 1px solid $light;


        .bottom-hover{
            border-bottom:1px solid $light
        }

        svg.cnr-animation{
            fill:$custom-neutral;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 20%;
            transform-origin: bottom right;
            transform: scale(1);
            z-index: 2;
            @include media-breakpoint-up(lg){
                max-width: 9vw;
            }
        }
        svg.cnr-animation{
            transition: all .2s ease-in-out;
            transform: scale(0);
        }


        &:hover{
            border: 1px solid $custom-accent-blue;
            background-color: $light;
            transition: all .2s ease-in-out;
            .large-cust-font{
                transition: all .2s ease-in-out;
                // border-bottom:1px solid $primary;
            }
            .add-border{
                transition: all .2s ease-in-out;
                border-bottom: 1px solid $primary;
            }

            svg.cnr-animation{
                transition: all .2s ease-in-out;
                transform: scale(1);
            }

            .bottom-hover{
                border-bottom:1px solid $primary
            }
        }
    }
}

.events-style-two{
    .zero-ten a{
        background-color: $custom-accent-blue;
        background-image: url(/hapai-public/img/side-pattern-one.png);
    }
    .bg-organisation a,
    .ten-plus a{
        background-color: $success;
        background-image: url(/hapai-public/img/side-pattern-one.png);
    }
    .bg-individual a,
    .all-levels a{
        background-color: $secondary;
        background-image: url(/hapai-public/img/side-pattern-one.png);
        
    }
    a{
        position: relative;
        overflow: hidden;
        background-size: 25%;
        background-position: 100% 100%;
        background-repeat: repeat-y;
        svg.cnr-animation{
            fill:$custom-neutral;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 20%;
            transform-origin: bottom right;
            transform: scale(1);
            z-index: 2;
            @include media-breakpoint-up(lg){
                max-width: 9vw;
            }
        }

        svg.cnr-animation{
            transition: all .2s ease-in-out;
            transform: scale(0);
        }


        &:hover{
            svg.cnr-animation{
                transition: all .2s ease-in-out;
                transform: scale(1);
            }
            .btn{
                border-top-left-radius: 0;
                transition: all .2s ease-in-out;
            }
        }
    }
}



// swiper js sliders

.swiper-slide{
    height: unset;
}

.swiper-button-disabled p{
    opacity: 50%;
}

.swiper-button-prev,
.swiper-button-next{
    &::after{
        display: none;
    }
}

.swiper-controls-wrapper{
    position: relative;
    padding-bottom: 10px;

    .swiper-button-prev,
    .swiper-button-next{
        top: 0;
        height:unset;
        width: unset;
        svg {
            width: 30px;
            height: 30px;
            path{
                stroke:$primary
            }
        }
    }

    .swiper-button-next{
        left:50px;
        right:unset;
    }

    .swiper-button-prev{
        left: 0;
        right: auto;
    }


    @include media-breakpoint-up(md){

        .swiper-button-prev{
            left:unset;
            right:calc(2vw + 40px );
        }
        .swiper-button-next{
            left:unset;
            right:2vw;
        }
    }

    @include media-breakpoint-up(highRes){
        .swiper-button-prev,
        .swiper-button-next{
            svg{
                width: 60px;
                height: 60px;
            }
        }
        .swiper-button-prev{
            right:calc(2vw + 80px );
        }
    }
}

.training-slider{
    .flex-styles{
        display: flex;
        flex-direction: column;
        height: 100%;
        @include media-breakpoint-up(sm){
            flex-direction: row;
            .col:not(.image-cnr){
                div{
                    p{
                        text-wrap: balance;
                    }
                }
            }
        }
        @include media-breakpoint-up(lg){
            .col:not(.image-cnr){
                div{
                    max-width:90%
                }
            }
        }
        @include media-breakpoint-up(xl){
            .col:not(.image-cnr){
                div{
                    max-width:100%
                }
            }
        }
    }



   .swiper-slide.swiper-slide-next{
    .flex-styles{
        
        @include media-breakpoint-up(md){
            flex-direction: row-reverse;
            .col:not(.image-cnr){
                text-align: right;
                div{
                    margin-left: auto;
                }
            }
        }
    }
   }
}

.who-slider{
    .swiper-slide{
        img,h2,h3,p{
           opacity: .5;
        }
    }

    .swiper-slide.swiper-slide-active{
        img,h2,h3,p{
            opacity: 1;
        }
    }
}



.home-page,
.past-events-slider{
    .swiper-slide{
        height: unset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}




// specific pages
.faq-page{
    .hero{
        background-image: url('/hapai-public/img/faq-hero.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 50% 100%;
    }
}

.who-we-are-page{
    .content-filter .side-buttons div:first-of-type{
        border-top:1px solid;
    }
}



.home-page{
    .image-cnr{
        position: relative;
    }

    .hero{
        @include media-breakpoint-up(md){
            .image-cnr.cnr-constant{
                svg.cnr-animation{
                    bottom: unset;
                    right:unset;
                    left: -1px;
                    top:-1px;
                    transform: rotate(180deg);
                    transform-origin: unset;
                } 
            }
        }
    }
    

    .swiper-controls-wrapper{
        .swiper-button-prev,
        .swiper-button-next {
           top:60px;
            svg {
                width: 50px;
                height: 50px;
            }

            @include media-breakpoint-up(md){
                top:-25px;
            }

            @include media-breakpoint-up(highRes){
                top:-50px;
                svg {
                    width: 100px;
                    height: 100px;
                }
            }
        }


        .swiper-button-next{
            right:unset;
            left:70px;
        }
        .swiper-button-prev{
            right:unset;
            left:0vw
        }

        @include media-breakpoint-up(highRes){
            .swiper-button-next{
                left:140px;
            }
        }
    }


    .swiper-slide{
        .get-height .cust-font-5{
            font-weight:700 ;
        }
        .cust-font-7{
            font-weight:400
        }
        @include media-breakpoint-up(md){
            justify-content: flex-end;
        }
    }

}

.member-directory-page {

    ul.list-filter li{
        cursor: pointer;
        transition: color .2s ease-in-out;
        &.active,
        &:hover{
            color:$secondary
        }
    
    }
    ul.column-count{
       
        li{
            margin-bottom: 1rem;
        }
        .letter-heading{
            color: $success;
        }
        @include media-breakpoint-up(md){
            column-count: 2;
            column-gap: 4vw;
        }
    }
}













//modals
#team{
    .modal-header{
        border-bottom-width: 0;
    }
    .modal-content{
        width: 95% ;
        margin-left: auto;
        margin-right:0;
    }
    .bg-popout{
        background-image: url('/images/background-pop-out.svg');
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-repeat: repeat-x;
        background-size: contain;
        height: 10vw;
    }

    .filter-item{
        transition: opacity .2s ease-in-out;
        &:hover{
            opacity: 0.8;
            transition: opacity .2s ease-in-out;
        }
    }
    @include media-breakpoint-up(md){
        .modal-content{
            width: 75% ;
        }
    }
}



























/////footer element, repeated all pages
footer{
    p{
        text-wrap: wrap;
    }
    input{
        text-transform: uppercase;
        border-width:0;
        transition: all .2s ease-in-out;
        border-radius:1.25rem;
        font-size: .85rem;
        font-family: $button-font;
        &:hover,
        &:focus-visible{
            transition: all .2s ease-in-out;
            border-top-left-radius: 0;
            outline-color: $custom-accent-green;
        }
        @include media-breakpoint-up(highRes){
            border-radius:2.5rem;
            font-size: 1.7rem;
        }
    }

    ul{
        list-style: none;
    }

    .footer-nav{
        li{
            font-family: $button-font;
            a{
                color: $custom-neutral;
                text-transform: uppercase;
                line-height: 1.5em;
                text-wrap: nowrap;
                font-size:15px;
                @include media-breakpoint-up(highRes){
                    font-size:2rem
                }
            }
        }
    }


    svg.bi{
        fill:$custom-neutral;
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
        @include media-breakpoint-up(highRes){
            width: 40px;
            height: 40px;
        }
    }

    .footer-banner{
        width: 100%;
        display:none;
        @include media-breakpoint-up(md){
            display: block;
        }
    }

    .footer-nav-wrapper{
        background-image: url(/hapai-public/img/footer-pattern.svg);
        background-size: auto 100%;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md){
            background-size: 0 0;
        }
    }

}







///nav dropdown and content styling
/// 
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.hero{
    padding-top: 80px;
    @include media-breakpoint-up(lg){
        padding-top: 98px;
    }
    @include media-breakpoint-up(highRes){
        padding-top: 120px;
    }
}


////---------NAVIGATION AND MODALS


.no-scroll {
    touch-action: none;
    overflow: hidden;
    width: 100%;
}

#navbarNavDropdown {
    overflow: scroll;
}


nav{
    .navbar-collapse.bg-primary{
        .nav-link{
            color: #526D52;
            &:hover{
                color: $secondary;
            }
        }

        .dropdown-item{
            color:$secondary;
            &:hover{
                color: #526D52;
            }
        }

        p{
            color: $secondary;
        }
        .contact-nav p a{
            color: $secondary;
            &:hover{
                color: $light;
            }
        }
    }

    .navbar-nav{
        a:hover{
            color: $secondary;
        }
    }

    .dropdown-menu{
        border-width: 0;
        border-radius: 0;
        background-color: transparent;
    }

    .dropdown-toggle:after{
        display: none;
    }

    .navbar-toggler{
        border-width: 0;
        height: 16px;
        display: flex;
        align-items: center;
    }

    .nav-item{
        .dropdown-item{
            font-size: 1.5rem;
        }
    }

    .nav-wrapper{
        padding-bottom: 60px;
    }


    @include media-breakpoint-up(md){
        .nav-item{
            display: flex;
        }
        .navbar-nav{
            position: relative;
        }
        .nav-item{
            padding-top: .5rem;
            .dropdown-item{
                padding-top: .5em;
            }
        }
        .dropdown{
            position: unset;
        }
        .dropdown-menu.show{
            position: absolute;
        }
    
        .dropdown-menu[data-bs-popper]{
            top: unset;
            bottom: 0;
            left: 50%;
            padding: 0;
            margin:0
        }

        .contact-nav{
            text-align: right;
            padding-bottom: 0;
        }

        .nav-wrapper{
            padding-bottom: 15vw;
            margin-bottom: 0;
            margin-top: auto;
        }
    }


    @include media-breakpoint-up(lg){
        .nav-item{
            .dropdown-item{
                padding-top: 1em;
            }
        }
        .nav-wrapper{
            padding-bottom: 12vw;
        }
    }

    @include media-breakpoint-up(xl){
        .nav-wrapper{
            padding-bottom: 4vw;
        }
    }



    @include media-breakpoint-up(highRes){
        .nav-item{
            padding-top: 5rem;
            .dropdown-item{
                font-size: 4rem;
            }
        }
        .navbar-toggler{
            height: 30px;
        }
    }
}


//logo, hamburger and top background color changes on hamburger toggle
.navbar{
    .navbar-nav,
    .contact-nav{
        opacity: 0;
    }
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height .3s ease;
    }
    .navbar-collapse.show{
        height: 100vh;
        max-height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
       .navbar-nav,
        .contact-nav{
            transition: opacity .01s ease;
            opacity: 1;
        }
    }

    .bg-change{
        align-items: center;
        background-color: $light;
        .cta-wrapper{
            align-items: center;
            height: 100%;
          }
    }


    #logo{
        fill:$primary;
        width: 200px;

    }

    #burger{
        width: 20px;
        height: 15px;
        line{
            stroke:$primary;
            transition: all 0.5s ease-in-out;
        }
        .hide-me {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }

        .line-top,
        .line-bottom {
            transform-origin: 50% 50%;
        }

        &.cross{
            .hide-me {
                opacity: 0;
              }
              .line-top {
                transform: rotate(45deg) translateY(7px);
              }
              .line-bottom {
                transform: rotate(-45deg) translateY(-7px);
              }
        }
    }




    .bg-change, #logo,  #basket{
        transition: all .4s ease-in-out;
    }

    #basket{
        width: 22px;
        stroke:$primary;
        stroke-width: 1.5;
        fill:none;

    }


    &.nav-change {
        .bg-change{
            background-color: $primary;
            transition: background-color .2s ease-in-out;
            #main-btn{
                background-color: $light;
                color: $primary;
            }
            #basket{
                stroke:$light;
            }
            #logo{
                fill:$light;
            }
            #burger{
                line{
                    stroke:$light;
                }
                overflow: visible;
            }
        }
    }

    @include media-breakpoint-up(highRes){
        #logo{
            width: 400px;
        }
        #burger{
            width: 40px;
            height: 30px; 
            &.cross{
                .line-top {
                    transform: rotate(45deg) translateY(7px);
                }
                .line-bottom {
                    transform: rotate(-45deg) translateY(-7px);
                }
            }
        }
        #basket{
            width: 44px;
        }

        .navbar-collapse.show{
            min-height: calc(100vh - 120px);
        }
    }
    
}

.primary-nav{
    .navbar{
        #main-btn{
            background-color: $light;
            color: $primary;
        }
        #basket{
            stroke:$light;
        }
        .bg-change{
            background-color: $primary;
            #logo{
                fill:$light;
            }
            #burger{
                line{
                    stroke:$light;
                }
            }
        }
    }
}

.yellow-nav{
    .navbar{
        .bg-change{
            background-color: $custom-accent-yellow;
        }
        &.nav-change{
            .bg-change{
                background-color: $primary;
        }
    }
    }
}

.light-green-nav{
    .navbar{
        .bg-change{
            background-color: $secondary;
        }
        &.nav-change{
            .bg-change{
                background-color: $primary;
        }
    }
    }
}



////modal pop out cart
@mixin counter-btn($size) {
    padding:calc($size*0.4) ;
    margin-right:calc($size*0.5);
    margin-top:calc($size*2.2);
    font-size:calc($size*1.2);
    border-radius:calc($size*.8);

    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cartSummary{
    .modal-content{
        width: 95% ;
        margin-left: auto;
        margin-right:0;
    }
    .modal-header{
        border-bottom-width: 0;
    }

    .cart-item{
        border-bottom: 1px solid $primary;
        span{
            font-family: $button-font;
            font-weight: 500;
        }
        &:last-of-type{
           border-bottom:none
        }

    }


    @include media-breakpoint-up(md){
        .modal-content{
            width: 50% ;
        }
    }
    @include media-breakpoint-up(lg){
        .modal-content{
            width: 30% ;
        }
    }

    @include media-breakpoint-up(highRes){
        .btn-close{
            background-size:2em;
        }
    }
}


.cart-counter{
    border:1px solid $primary;
    font-family:$body-font;
    &.bg-primary{
        color: $light;
    }
    svg{
        width: 10px;
        height: 10px;
        fill: none;
        stroke: $light;
        stroke-width: 1.5
    }


    @include counter-btn(1em);
    @include media-breakpoint-up(highRes){
        @include counter-btn(2em);
        // svg{
        //     width: 50px;
        //     height: 50px;
        // }
    }
}

#cartSummary2{
    // .cart-counter{
    //     font-family:$heading-font-bold
    // }
    .cart-item:last-of-type{
        border-bottom-width:0 !important;
    }
    @include media-breakpoint-up(highRes){
        .modal-dialog{
            max-width:1600px !important
        }
        
    }
}



////svg graphics

.akoranga,
.whanaungatanga,
.kaitiakitanga{
    width: 100%;
    &.sm{
        width: 100px;
        height: 100px;
        @include media-breakpoint-up(highRes){
                width: 200px;
                height: 200px;
        }
    }
    @include media-breakpoint-up(md){
        &.height{
            width: auto;
            height: 100%;
        }
    }
}

.kaitiakitanga{
    .cls-1, .cls-2, .cls-3 {
        fill: none;
      }

      .cls-2 {
        stroke: #ecece6;
        stroke-width: .8px;
      }

      .cls-2, .cls-3 {
        stroke-miterlimit: 10;
      }

      .cls-3 {
        stroke: #112424;
        stroke-width: .6px;
      }

}


.whanaungatanga{
    .cls-1, .cls-2, .cls-3, .cls-4 {
        fill: none;
      }

      .cls-2 {
        stroke: #ecece6;
        stroke-width: .8px;
      }

      .cls-2, .cls-3, .cls-4 {
        stroke-miterlimit: 10;
      }


      .cls-3 {
        stroke-width: .6px;
      }

      .cls-3, .cls-4 {
        stroke: #112424;
      }

      .cls-4 {
        stroke-width: .6px;
      }

}

.akoranga{
    .cls-1, .cls-2, .cls-3 {
        fill: none;
      }

      .cls-2 {
        stroke: #ecece6;
        stroke-width: .8px;
      }

      .cls-2, .cls-3 {
        stroke-miterlimit: 10;
      }


      .cls-3 {
        stroke: #112424;
        stroke-width: .6px;
      }
}

#identity-banner,
#roc-grotesk,
#founders-grotesk,
#tobias-light,
#identity{
    width: 100%;
    height: auto;
    fill: $primary;
    position: relative;
}
#roc-grotesk,
#founders-grotesk{
    width: 30%;
}


    @include media-breakpoint-up(highRes){
    #tobias-light{
        width: 466px;
    } 
    #founders-grotesk,
    #roc-grotesk{
        width: 100px;
    }
}


.animated-section{
    position: relative;
    overflow: hidden;

    .custom-primary{
        position: relative;
    }
    @include media-breakpoint-up(md){
        height: calc(100vh - 80px);
        .custom-primary{
            height: calc(100vh - 80px);
            z-index: 10;
        }
        
    }
}


.form-pages{
    min-height: 100vh;
    .logo-nav{
        display: flex;
        align-items: center;
        flex-direction: column;
        a{
            width: 230px;
           #logo{
                fill: $primary;
            }
            #footer-logo{
                fill: $custom-accent-yellow;
            }
        }
    }

    .help-wrapper{
        p{
            color:$custom-dark-grey
        }
    }

    input[type="date"] {
       margin-top:0
      }

      .form-tab {
        display: none;
      }

      #prevBtn{
        visibility:hidden;
      }

      input.invalid {
        border-bottom: 1px solid red; /* Highlight invalid inputs with a red border */
      }


    .step {
        height:.5em;
        width: .5em;
        margin: 0 .1em;
        background-color: #07332A;
        border: none;  
        border-radius: 50%;
        display: inline-block;
        opacity: 0.5;
      }
      
      .step.active {
        opacity: 1;
      }

      @include media-breakpoint-up(highRes){
        .logo-nav{
            a{
                width: 460px;
            }
        }
    }
}


@include media-breakpoint-up(highRes){
    .form-select{
        font-size:2em
    }
}